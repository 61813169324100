import { useHttp } from '@/middleware/httpMiddleware';

async function getProblems () {
  const data = await useHttp(
    '/admin/problems',
    'GET'
  );

  return data.payload;
}

async function updateProblem (problemId, status = '') {
  const data = await useHttp(
    `/admin/problems/${problemId}/update`,
    'POST',
    {
      status,
    }
  );

  return data.payload;
}

export {
  getProblems,
  updateProblem,
};
