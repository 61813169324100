<template>
  <base-page-item>
    <v-card class="pa-6">
      <v-row
        class="spacer mb-6"
        no-gutters
      >
        <v-col cols="3">
          <p class="text-left mb-0">
            <span class="font-weight-bold mr-1">Level: </span> {{ localProblem.level }}
          </p>
          <p class="text-left mb-0">
            <span class="font-weight-bold mr-1">Type: </span> {{ localProblem.type }}
          </p>
          <p class="text-left mb-0">
            <span class="font-weight-bold mr-1">Status: </span> {{ localProblem.status }}
          </p>
        </v-col>
        <v-col cols="3">
          <p class="text-left mb-0 font-weight-bold">
            {{ credential.first_name }} {{ credential.last_name }}
          </p>
          <p class="text-left mb-0">
            {{ credential.username }}
          </p>
          <p class="text-left mb-0">
            <span class="font-weight-bold mr-2">Account status:</span>
            <span
              :class="{
                'success--text': credential.status === 'active',
                'grey--text': credential.status === 'inactive',
                'warning--text': credential.status === 'invalid',
                'error--text': credential.status === 'deleted',
              }"
              class="text-capitalize "
            >{{ credential.status }}
            </span>
          </p>
          <a
            :href="`https://x.divo.ai/campaigns?space=${credential.space._id}&selectedCred=${credential._id}`"
            class="href"
            target="_blank"
          > Open in Divo </a>
          <br>
          <a
            href="#"
            class="href"
            @click="forwardToCredentials"
          >Open in panel</a>
        </v-col>
        <v-col cols="3">
          <p class="text-left mb-0">
            <span class="font-weight-bold mr-1">Confirmations count: </span> {{ localProblem.confirmations }}
          </p>
          <p class="text-left mb-0">
            <span class="font-weight-bold mr-1">Confirmed at: </span> {{ localProblem.confirmed_at }}
          </p>
          <p class="text-left mb-0">
            <span class="font-weight-bold mr-1">Created at: </span> {{ localProblem.created_at }}
          </p>
        </v-col>
        <v-col cols="1" />
        <v-col cols="1">
          <v-btn
            v-if="localProblem.status === 'new'"
            depressed
            color="success"
            class="mb-4"
            @click="updateProblem('done')"
          >
            mark resolved
          </v-btn>
          <v-btn
            v-if="localProblem.status === 'done'"
            depressed
            color="gray"
            @click="updateProblem('new')"
          >
            mark new
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </base-page-item>
</template>

<script>
  import BasePageItem from '@/components/base/BasePageItem';
  import { updateProblem } from '@/api/problemsMethods';

  export default {
    name: 'ProblemsItem',
    components: {
      BasePageItem,
    },
    props: {
      problem: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        localProblem: this.problem,
      };
    },
    computed: {
      credential () {
        return this.localProblem.credentials;
      },
    },
    methods: {
      async updateProblem (status = 'done') {
        this.localProblem = await updateProblem(this.localProblem._id, status);
      },
      async forwardToCredentials () {
        await this.$store.dispatch('credentials/SET_FILTERS', { q: { value: this.credential._id } });
        await this.$router.push({ name: 'credentials' });
      },
    },
  };
</script>

<style scoped>

</style>
